import moment from "moment";

const labels = [
    '2023-05-01', '2023-05-02', '2023-05-03', '2023-05-04', '2023-05-05', '2023-05-06',
    '2023-05-07', '2023-05-08', '2023-05-09', '2023-05-10', '2023-05-11', '2023-05-12',
    '2023-05-13', '2023-05-14', '2023-05-15', '2023-05-16', '2023-05-17', '2023-05-18',
    '2023-05-19', '2023-05-20', '2023-05-21', '2023-05-22', '2023-05-23', '2023-05-24'];


export const option_1 = {
    type: "line_1_chart",
    responsive: true,
   
    plugins: {
      zoom: {
				zoom: {
					drag: {
						enabled: true,
						threshold: 801, 
            backgroundColor: 'rgba(195, 240, 237, 1)'// set threshold as described in docs: https://www.chartjs.org/chartjs-plugin-zoom/latest/guide/options.html#drag-options
					},
					mode: 'x',
				}
			},
      legend: {
        display: false
      },
      title: {
        display: true,
        text: "Alcohol (mg/L)"
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
      hover: {
        mode: 'all',
        intersect: false
      },
     
    },
    elements: {
      point: {
        radius: 0
      }
    },
    interaction: {
      intersect: false,
    },
    
    scales: {
        x: {
          ticks: {
            type: 'time',
            /* time: {
              unit: 'minute',
              stepSize: 60,
              displayFormats: {
                minute: 'mm-dd hh:mm a'
              }
            }, */
            callback: function(val, index) {
              // console.log(this.getLabelForValue(val), this.getLabelForValue(val).minute(), this.getLabelForValue(val).minute()%60 === 0);
              if (this.getLabelForValue(val)) {
                const labelValue = new moment(this.getLabelForValue(val));
                return labelValue.minute()%60 === 0 ? labelValue.format("MM-DD hh:mm a") : '';
                
              }
              return '';
            }
              
          },
          display: true,
          grid: {
              display: false
          }
        },
        y: {
          display: true,
          beginAtZero: true,
          suggestedMax: 0.2,
          
        }
    }
};

export const option_2 = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          // This more specific font property overrides the global property
          font: {
              size: 10
          }
        }
      },
      title: {
        display: true,
        text: "Alarms"
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
      hover: {
        mode: 'all',
        intersect: false
      }
    },
    elements: {
      point: {
        radius: 0
      }
    },
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          display: false
        },
        display: true,
        grid: {
          display: false
        }
      },
      y: {
        display: true,
        beginAtZero: true,
        stacked: true,
        ticks: {
          precision: 0
        }
        
      }
    }
};

export const option_3 = {
    responsive: true,
    plugins: {
      zoom: {
				zoom: {
					drag: {
						enabled: true,
						threshold: 801, 
            backgroundColor: 'rgba(195, 240, 237, 1)'// set threshold as described in docs: https://www.chartjs.org/chartjs-plugin-zoom/latest/guide/options.html#drag-options
					},
					mode: 'x',
				}
			},
      legend: {
        display: false
      },
      title: {
        display: true,
        text: "Touch Voltage"
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
      hover: {
        mode: 'all',
        intersect: false
      }
    },
    elements: {
      point: {
        radius: 0
      }
    },
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        ticks: {
          type: 'time',
          callback: function(val, index) {
            // console.log(this.getLabelForValue(val), this.getLabelForValue(val).minute(), this.getLabelForValue(val).minute()%60 === 0);
            if (this.getLabelForValue(val)) {
              const labelValue = new moment(this.getLabelForValue(val));
              return labelValue.minute()%60 === 0 ? labelValue.format("MM-DD hh:mm a") : '';
              
            }
            return '';
          }
            
        },
        display: true,
        grid: {
          display: false
        }
      },
      y: {
        display: true,
        beginAtZero: true
      }
    }
};

export const option_4 = {
    responsive: true,
    plugins: {
      zoom: {
				zoom: {
					drag: {
						enabled: true,
						threshold: 801, 
            backgroundColor: 'rgba(195, 240, 237, 1)'// set threshold as described in docs: https://www.chartjs.org/chartjs-plugin-zoom/latest/guide/options.html#drag-options
					},
					mode: 'x',
				}
			},

      legend: {
        display: false
      },
      title: {
        display: true,
        text: "Ambient Pressure"
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
      hover: {
        mode: 'all',
        intersect: false
      }
    },
    elements: {
      point: {
        radius: 0
      }
    },
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        ticks: {
          type: 'time',
          callback: function(val, index) {
            // console.log(this.getLabelForValue(val), this.getLabelForValue(val).minute(), this.getLabelForValue(val).minute()%60 === 0);
            if (this.getLabelForValue(val)) {
              const labelValue = new moment(this.getLabelForValue(val));
              return labelValue.minute()%60 === 0 ? labelValue.format("MM-DD hh:mm a") : '';
              
            }
            return '';
          }
            
        },
        display: true,
        grid: {
          display: false
        }
      },
      y: {
        display: true,
        beginAtZero: true,
        suggestedMax: 120
      }
    }
};

export const option_5 = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: "Watch Battery %"
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
      hover: {
        mode: 'all',
        intersect: false
      }
    },
    elements: {
      point: {
        radius: 0
      }
    },
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        ticks: {
          type: 'time',
          callback: function(val, index) {
            // console.log(this.getLabelForValue(val), this.getLabelForValue(val).minute(), this.getLabelForValue(val).minute()%60 === 0);
            if (this.getLabelForValue(val)) {
              const labelValue = new moment(this.getLabelForValue(val));
              return labelValue.minute()%60 === 0 ? labelValue.format("MM-DD hh:mm a") : '';
              
            }
            return '';
          }
            
        },
        display: true,
        grid: {
          display: false
        }
      },
      y: {
        display: true,
        beginAtZero: true,
        suggestedMax: 100
      }
    }
};

export const option_6 = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: "Phone Battery %"
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
      hover: {
        mode: 'all',
        intersect: false
      }
    },
    elements: {
      point: {
        radius: 0
      }
    },
    interaction: {
      intersect: false,
       mode: 'index'
    },
    scales: {
      x: {
        ticks: {
          type: 'time',
          callback: function(val, index) {
            // console.log(this.getLabelForValue(val), this.getLabelForValue(val).minute(), this.getLabelForValue(val).minute()%60 === 0);
            if (this.getLabelForValue(val)) {
              const labelValue = new moment(this.getLabelForValue(val));
              return labelValue.minute()%60 === 0 ? labelValue.format("MM-DD hh:mm a") : '';
              
            }
            return '';
          }
            
        },
        display: true,
        grid: {
          display: false
        }
      },
      y: {
        display: true,
        beginAtZero: true,
        suggestedMax: 100
      }
    }
};
export const alcohalConcentrationData = {
    labels,
    datasets: [
      {
        data: [0.0, 0.12, 0.0, 0.05, 0.0, 0.03, 0.0, 0.02, 0.0, 0.1, 0.19, 0.1, 0.06, 0.1, 0.07, 0.1, 0.05, 0.1, 0.04, 0.1, 0.02, 0.0, 0.17, 0.1],
        borderColor: "#4270c3",
        backgroundColor: "#4270c3",
        lineTension: 0.3
      },
      {
        data: [0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05],
        borderColor: "#ee414f",
        backgroundColor: "#ee414f",
        fill: false,
        lineTension: 0.3,
        type: "line",
        borderDash: [20, 15]
      },
      {
        type: 'bar',
        borderColor: '#1cc6bc',
        backgroundColor: "#1cc6bc",
        fill: false,
        data: [ 0, 0, 0, 0, 0, 0, 0, 0, 0.2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        maxBarThickness: 2,
      }
    ]
};

export const alarmsData = {
    labels,
    datasets: [
      {
        label: 'alarmTouch',
        fille: true,
        data: [ 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 1, 0, 0, 1, 1, 1, 1, 0, 1, 0, 0, 1, 1, 1, 1],
        borderColor: "#ed7d31",
        backgroundColor: "#ed7d31",
        lineTension: 0.1,
        fill: true,
        order: 1
      },
      {
        label: 'alarmLowTouch',
        data: [ 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 0, 0, 1, 0, 0, 0, 0, 0, 0, 1, 1, 0 ],
        borderColor: "#a5a5a5",
        backgroundColor: "#a5a5a5",
        fill: true,
        lineTension: 0.1,
        order: 1
      },
      {
        label: 'alarmPressure',
        data: [ 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 1, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0 ],
        borderColor: "#ffc000",
        backgroundColor: "#ffc000",
        fill: true,
        lineTension: 0.1,
        order: 1
      },
      {
        label: 'alarmStrap',
        data: [ 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1 ],
        borderColor: "#5b9bd5",
        backgroundColor: "#5b9bd5",
        fill: true,
        lineTension: 0.1,
        order: 1
      },
      {
        label: 'alarmTamper',
        data: [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0 ],
        borderColor: "#264478",
        backgroundColor: "#264478",
        fill: true,
        lineTension: 0.1,
        order: 1
      },
      {
        label: 'alarmBlocked',
        data: [ 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ],
        borderColor: "#70ad47",
        backgroundColor: "#70ad47",
        fill: true,
        lineTension: 0.1,
        order: 1
      },
      /* {
        type: 'bar',
        borderColor: '#1cc6bc',
        backgroundColor: "#1cc6bc",
        fill: false,
        data: [ 0, 0, 0, 0, 0, 0, 0, 0, 4.5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        maxBarThickness: 1,
        order: 0,
        stacked: false
      } */
    ]
};

export const touchVoltageData = {
    labels,
    datasets: [
      {
        data: [ 0, 4000, 4000, 4000, 4000, 3800, 3900, 3800, 4000, 3400, 3800, 2900, 4100, 2000, 1800, 500, 200, 4100, 200, 4000, 100, 2000, 500, 2500 ],
        borderColor: "#4270c3",
        backgroundColor: "#4270c3",
        lineTension: 0.3
      },
      {
        data: [ 3700, 3700, 3700, 3700, 3700, 3700, 3700, 3700, 3700, 3700, 3700, 3700, 3700, 3700, 3700, 3700, 3700, 3700, 3700, 3700, 3700, 3700, 3700, 3700 ],
        borderColor: "#ffa467",
        backgroundColor: "#e9ffea",
        fill: 2,
        lineTension: 0.3,
        type: "line",
        borderDash: [20, 15],
        order: 2
      },
      {
        data: [ 500,500,500,500,500,500,500,500,500,500,500,500,500,500,500,500,500,500,500,500,500,500,500,500],
        borderColor: "#ffa467",
        backgroundColor: "#ffa467",
        fill: false,
        lineTension: 0.3,
        type: "line",
        borderDash: [20, 15]
      },
      {
        type: 'bar',
        borderColor: '#1cc6bc',
        backgroundColor: "#1cc6bc",
        fill: false,
        data: [ 0, 0, 0, 0, 0, 0, 0, 0, 4500, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        maxBarThickness: 2,
        order: 1
      }
    ]
};

export const pressureData = {
    labels,
    datasets: [
      {
        data: [100, 101, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        borderColor: "#4270c3",
        backgroundColor: "#4270c3",
        lineTension: 0.3
      },
      {
        data: [80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80],
        borderColor: "#ee414f",
        backgroundColor: "#ee414f",
        fill: false,
        lineTension: 0.3,
        type: "line",
        borderDash: [20, 15]
      },
      {
        type: 'bar',
        borderColor: '#1cc6bc',
        backgroundColor: "#1cc6bc",
        fill: false,
        data: [ 0, 0, 0, 0, 0, 0, 0, 0, 120, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        maxBarThickness: 2,
      }
    ]
};

export const batteryPercentage = {
    labels,
    datasets: [
      {
        data: [0, 90, 90, 90, 90, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 79, 80, 79, 80, 79, 89, 89, 90, 90],
        borderColor: "#4270c3",
        backgroundColor: "#4270c3",
        lineTension: 0.3
      },
      {
        data: [20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20],
        borderColor: "#ffa467",
        backgroundColor: "#ffa467",
        fill: false,
        lineTension: 0.3,
        type: "line",
        borderDash: [20, 15]
      },
      {
        data: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
        borderColor: "#ee414f",
        backgroundColor: "#ee414f",
        fill: false,
        lineTension: 0.3,
        type: "line",
        borderDash: [20, 15]
      },
      {
        type: 'bar',
        borderColor: '#1cc6bc',
        backgroundColor: "#1cc6bc",
        fill: false,
        data: [ 0, 0, 0, 0, 0, 0, 0, 0, 100, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        maxBarThickness: 2,
      }
      
    ]
};

export const phoneBatteryPercentage = {
    labels,
    datasets: [
      {
        data: [0, 100, 100, 100, 100, 100, 100, 100, 100, 99, 99, 99, 99, 98, 97, 96, 95, 95, 95, 94, 94, 93, 92, 91],
        borderColor: "#4270c3",
        backgroundColor: "#4270c3",
        lineTension: 0.3
      },
      {
        type: 'bar',
        borderColor: '#1cc6bc',
        backgroundColor: "#1cc6bc",
        fill: false,
        data: [ 0, 0, 0, 0, 0, 0, 0, 0, 120, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        maxBarThickness: 2,
      }
      
    ]
};