import React, { useState, useEffect, useRef, useCallback } from "react";
import { StyledButton } from "theme/StyledComponents";
import { Row, Col, Select, Input, Button, notification } from "antd";
import { css } from "styled-components";
import { StyledModal } from "./ManageZones.style";
import apiHandler from "api";
import endpoint from "api/endpoint";
import { useSelector } from "react-redux";
import "./ManageZonesCustom.css";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Circle,
  Polygon,
} from "@react-google-maps/api";
import Autocomplete from "react-google-autocomplete";

const center = { lat: 48.8584, lng: 2.2945 };

const RADIUS_ON_ZOOM = {
  ZOOM_22: 5,
  ZOOM_21: 10,
  ZOOM_20: 20,
  ZOOM_19: 40,
  ZOOM_18: 80,
  ZOOM_17: 160,
  ZOOM_16: 320,
  ZOOM_15: 640,
  ZOOM_14: 1280,
  ZOOM_13: 2560,
  ZOOM_12: 5120,
  ZOOM_11: 10240,
  ZOOM_10: 20480,
  ZOOM_9: 40960,
  ZOOM_8: 81920,
  ZOOM_7: 163840,
  ZOOM_6: 327680,
  ZOOM_5: 655360,
  ZOOM_4: 1310720,
  ZOOM_3: 2621440,
  ZOOM_2: 2800000,
  ZOOM_1: 3000000,
  ZOOM_0: 3200000,
};

const containerStyle = {
  width: "100%",
  height: "60vh",
};

const ManageZones = () => {
  const { authToken } = useSelector((state) => state.login);
  const [modalVisible, setModalVisible] = useState(false);
  const { Option } = Select;
  const [zoneData, setZoneData] = useState([]);
  const [mapData, setMapData] = useState([{ lat: 48.8584, lng: 2.2945 }]);
  const { activeParticipantId } = useSelector((state) => state.common);
  const [zoneName, setZoneName] = useState("");
  const [zoneType, setzoneType] = useState("");
  const [radius, setRadius] = useState("50");
  const [circleRadius, setCircleRadius] = useState("50");
  const [coordinates, setCoordinates] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);
  const [addZone, setAddZone] = useState("");
  const zLabel = { isTrue: true, isFalse: false };
  const [shapeType, setShapeType] = useState("");
  const [places, setPlaces] = useState({});
  const [polygonPath, setPolygonPath] = useState([]);
  const [rectanglePath, setRectanglePath] = useState([]);
  const [rectangleCoordinate, setRectangleCoordinate] = useState([]);
  const listenersRef = useRef([]);
  const [isShow, setShow] = useState(false);
  const inputEl = useRef(null);
  const rectangleRef = useRef(null);
  let circleRef = useRef(null);
  const [monthDays, setDays] = useState([]);
  const polygonRef = useRef(null);
  const [dynamicOptions, setDynamicOptions] = useState({
    strokeColor: "#32a858",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#32a858",
    fillOpacity: 0.35,
    clickable: true,
    draggable: true,
    editable: true,
    radius: 50,
    visible: true,
    zIndex: 1,
  });
  let week_names = [
    {
      dayName:'Monday',
      dayId: 1,
      timeInterval:[{startHour: 0, isChecked:0, CheckedMinutes:0, realName:"12AM"},{startHour: 1, isChecked:0, CheckedMinutes:0, realName:"1AM"},{startHour: 2, isChecked:0, CheckedMinutes:0, realName:"2AM"},{startHour: 3, isChecked:0, CheckedMinutes:0, realName:"3AM"},{startHour: 4, isChecked:0, CheckedMinutes:0, realName:"4AM"},{startHour: 5, isChecked:0, CheckedMinutes:0, realName:"5AM"},{startHour: 6, isChecked:0, CheckedMinutes:0, realName:"6AM"},{startHour: 7, isChecked:0, CheckedMinutes:0, realName:"7AM"},{startHour: 8, isChecked:0, CheckedMinutes:0, realName:"8AM"},{startHour: 9, isChecked:0, CheckedMinutes:0, realName:"9AM"},{startHour: 10, isChecked:0, CheckedMinutes:0, realName:"10AM"},{startHour: 11, isChecked:0, CheckedMinutes:0, realName:"11AM"},{startHour: 12, isChecked:0, CheckedMinutes:0, realName:"12PM"},{startHour: 13, isChecked:0, CheckedMinutes:0, realName:"1PM"},{startHour: 14, isChecked:0, CheckedMinutes:0, realName:"2PM"},{startHour: 15, isChecked:0, CheckedMinutes:0, realName:"3PM"},{startHour: 16, isChecked:0, CheckedMinutes:0, realName:"4PM"},{startHour: 17, isChecked:0, CheckedMinutes:0, realName:"5PM"},{startHour: 18, isChecked:0, CheckedMinutes:0, realName:"6PM"},{startHour: 19, isChecked:0, CheckedMinutes:0, realName:"7PM"},{startHour: 20, isChecked:0, CheckedMinutes:0, realName:"8PM"},{startHour: 21, isChecked:0, CheckedMinutes:0, realName:"9PM"},{startHour: 22, isChecked:0, CheckedMinutes:0, realName:"10PM"},{startHour: 23, isChecked:0, CheckedMinutes:0, realName:"11PM"}]
    },
    {
      dayName:'Tuesday',
      dayId: 2,
      timeInterval:[{startHour: 0, isChecked:0, CheckedMinutes:0, realName:"12AM"},{startHour: 1, isChecked:0, CheckedMinutes:0, realName:"01AM"},{startHour: 2, isChecked:0, CheckedMinutes:0, realName:"02AM"},{startHour: 3, isChecked:0, CheckedMinutes:0, realName:"03AM"},{startHour: 4, isChecked:0, CheckedMinutes:0, realName:"04AM"},{startHour: 5, isChecked:0, CheckedMinutes:0, realName:"05AM"},{startHour: 6, isChecked:0, CheckedMinutes:0, realName:"06AM"},{startHour: 7, isChecked:0, CheckedMinutes:0, realName:"07AM"},{startHour: 8, isChecked:0, CheckedMinutes:0, realName:"08AM"},{startHour: 9, isChecked:0, CheckedMinutes:0, realName:"09AM"},{startHour: 10, isChecked:0, CheckedMinutes:0, realName:"10AM"},{startHour: 11, isChecked:0, CheckedMinutes:0, realName:"11AM"},{startHour: 12, isChecked:0, CheckedMinutes:0, realName:"12PM"},{startHour: 13, isChecked:0, CheckedMinutes:0, realName:"01PM"},{startHour: 14, isChecked:0, CheckedMinutes:0, realName:"02PM"},{startHour: 15, isChecked:0, CheckedMinutes:0, realName:"03PM"},{startHour: 16, isChecked:0, CheckedMinutes:0, realName:"04PM"},{startHour: 17, isChecked:0, CheckedMinutes:0, realName:"05PM"},{startHour: 18, isChecked:0, CheckedMinutes:0, realName:"06PM"},{startHour: 19, isChecked:0, CheckedMinutes:0, realName:"07PM"},{startHour: 20, isChecked:0, CheckedMinutes:0, realName:"08PM"},{startHour: 21, isChecked:0, CheckedMinutes:0, realName:"09PM"},{startHour: 22, isChecked:0, CheckedMinutes:0, realName:"10PM"},{startHour: 23, isChecked:0, CheckedMinutes:0, realName:"11PM"}]

    },
    {
      dayName:'Wednesday',
      dayId: 3,
      timeInterval:[{startHour: 0, isChecked:0, CheckedMinutes:0, realName:"12AM"},{startHour: 1, isChecked:0, CheckedMinutes:0, realName:"01AM"},{startHour: 2, isChecked:0, CheckedMinutes:0, realName:"02AM"},{startHour: 3, isChecked:0, CheckedMinutes:0, realName:"03AM"},{startHour: 4, isChecked:0, CheckedMinutes:0, realName:"04AM"},{startHour: 5, isChecked:0, CheckedMinutes:0, realName:"05AM"},{startHour: 6, isChecked:0, CheckedMinutes:0, realName:"06AM"},{startHour: 7, isChecked:0, CheckedMinutes:0, realName:"07AM"},{startHour: 8, isChecked:0, CheckedMinutes:0, realName:"08AM"},{startHour: 9, isChecked:0, CheckedMinutes:0, realName:"09AM"},{startHour: 10, isChecked:0, CheckedMinutes:0, realName:"10AM"},{startHour: 11, isChecked:0, CheckedMinutes:0, realName:"11AM"},{startHour: 12, isChecked:0, CheckedMinutes:0, realName:"12PM"},{startHour: 13, isChecked:0, CheckedMinutes:0, realName:"01PM"},{startHour: 14, isChecked:0, CheckedMinutes:0, realName:"02PM"},{startHour: 15, isChecked:0, CheckedMinutes:0, realName:"03PM"},{startHour: 16, isChecked:0, CheckedMinutes:0, realName:"04PM"},{startHour: 17, isChecked:0, CheckedMinutes:0, realName:"05PM"},{startHour: 18, isChecked:0, CheckedMinutes:0, realName:"06PM"},{startHour: 19, isChecked:0, CheckedMinutes:0, realName:"07PM"},{startHour: 20, isChecked:0, CheckedMinutes:0, realName:"08PM"},{startHour: 21, isChecked:0, CheckedMinutes:0, realName:"09PM"},{startHour: 22, isChecked:0, CheckedMinutes:0, realName:"10PM"},{startHour: 23, isChecked:0, CheckedMinutes:0, realName:"11PM"}]

    },
    {
      dayName:'Thursday',
      dayId: 4,
      timeInterval:[{startHour: 0, isChecked:0, CheckedMinutes:0, realName:"12AM"},{startHour: 1, isChecked:0, CheckedMinutes:0, realName:"01AM"},{startHour: 2, isChecked:0, CheckedMinutes:0, realName:"02AM"},{startHour: 3, isChecked:0, CheckedMinutes:0, realName:"03AM"},{startHour: 4, isChecked:0, CheckedMinutes:0, realName:"04AM"},{startHour: 5, isChecked:0, CheckedMinutes:0, realName:"05AM"},{startHour: 6, isChecked:0, CheckedMinutes:0, realName:"06AM"},{startHour: 7, isChecked:0, CheckedMinutes:0, realName:"07AM"},{startHour: 8, isChecked:0, CheckedMinutes:0, realName:"08AM"},{startHour: 9, isChecked:0, CheckedMinutes:0, realName:"09AM"},{startHour: 10, isChecked:0, CheckedMinutes:0, realName:"10AM"},{startHour: 11, isChecked:0, CheckedMinutes:0, realName:"11AM"},{startHour: 12, isChecked:0, CheckedMinutes:0, realName:"12PM"},{startHour: 13, isChecked:0, CheckedMinutes:0, realName:"01PM"},{startHour: 14, isChecked:0, CheckedMinutes:0, realName:"02PM"},{startHour: 15, isChecked:0, CheckedMinutes:0, realName:"03PM"},{startHour: 16, isChecked:0, CheckedMinutes:0, realName:"04PM"},{startHour: 17, isChecked:0, CheckedMinutes:0, realName:"05PM"},{startHour: 18, isChecked:0, CheckedMinutes:0, realName:"06PM"},{startHour: 19, isChecked:0, CheckedMinutes:0, realName:"07PM"},{startHour: 20, isChecked:0, CheckedMinutes:0, realName:"08PM"},{startHour: 21, isChecked:0, CheckedMinutes:0, realName:"09PM"},{startHour: 22, isChecked:0, CheckedMinutes:0, realName:"10PM"},{startHour: 23, isChecked:0, CheckedMinutes:0, realName:"11PM"}]

    },
    {
      dayName:'Friday',
      dayId: 5,
      timeInterval:[{startHour: 0, isChecked:0, CheckedMinutes:0, realName:"12AM"},{startHour: 1, isChecked:0, CheckedMinutes:0, realName:"01AM"},{startHour: 2, isChecked:0, CheckedMinutes:0, realName:"02AM"},{startHour: 3, isChecked:0, CheckedMinutes:0, realName:"03AM"},{startHour: 4, isChecked:0, CheckedMinutes:0, realName:"04AM"},{startHour: 5, isChecked:0, CheckedMinutes:0, realName:"05AM"},{startHour: 6, isChecked:0, CheckedMinutes:0, realName:"06AM"},{startHour: 7, isChecked:0, CheckedMinutes:0, realName:"07AM"},{startHour: 8, isChecked:0, CheckedMinutes:0, realName:"08AM"},{startHour: 9, isChecked:0, CheckedMinutes:0, realName:"09AM"},{startHour: 10, isChecked:0, CheckedMinutes:0, realName:"10AM"},{startHour: 11, isChecked:0, CheckedMinutes:0, realName:"11AM"},{startHour: 12, isChecked:0, CheckedMinutes:0, realName:"12PM"},{startHour: 13, isChecked:0, CheckedMinutes:0, realName:"01PM"},{startHour: 14, isChecked:0, CheckedMinutes:0, realName:"02PM"},{startHour: 15, isChecked:0, CheckedMinutes:0, realName:"03PM"},{startHour: 16, isChecked:0, CheckedMinutes:0, realName:"04PM"},{startHour: 17, isChecked:0, CheckedMinutes:0, realName:"05PM"},{startHour: 18, isChecked:0, CheckedMinutes:0, realName:"06PM"},{startHour: 19, isChecked:0, CheckedMinutes:0, realName:"07PM"},{startHour: 20, isChecked:0, CheckedMinutes:0, realName:"08PM"},{startHour: 21, isChecked:0, CheckedMinutes:0, realName:"09PM"},{startHour: 22, isChecked:0, CheckedMinutes:0, realName:"10PM"},{startHour: 23, isChecked:0, CheckedMinutes:0, realName:"11PM"}]

    },
    {
      dayName:'Saturday',
      dayId: 6,
      timeInterval:[{startHour: 0, isChecked:0, CheckedMinutes:0, realName:"12AM"},{startHour: 1, isChecked:0, CheckedMinutes:0, realName:"01AM"},{startHour: 2, isChecked:0, CheckedMinutes:0, realName:"02AM"},{startHour: 3, isChecked:0, CheckedMinutes:0, realName:"03AM"},{startHour: 4, isChecked:0, CheckedMinutes:0, realName:"04AM"},{startHour: 5, isChecked:0, CheckedMinutes:0, realName:"05AM"},{startHour: 6, isChecked:0, CheckedMinutes:0, realName:"06AM"},{startHour: 7, isChecked:0, CheckedMinutes:0, realName:"07AM"},{startHour: 8, isChecked:0, CheckedMinutes:0, realName:"08AM"},{startHour: 9, isChecked:0, CheckedMinutes:0, realName:"09AM"},{startHour: 10, isChecked:0, CheckedMinutes:0, realName:"10AM"},{startHour: 11, isChecked:0, CheckedMinutes:0, realName:"11AM"},{startHour: 12, isChecked:0, CheckedMinutes:0, realName:"12PM"},{startHour: 13, isChecked:0, CheckedMinutes:0, realName:"01PM"},{startHour: 14, isChecked:0, CheckedMinutes:0, realName:"02PM"},{startHour: 15, isChecked:0, CheckedMinutes:0, realName:"03PM"},{startHour: 16, isChecked:0, CheckedMinutes:0, realName:"04PM"},{startHour: 17, isChecked:0, CheckedMinutes:0, realName:"05PM"},{startHour: 18, isChecked:0, CheckedMinutes:0, realName:"06PM"},{startHour: 19, isChecked:0, CheckedMinutes:0, realName:"07PM"},{startHour: 20, isChecked:0, CheckedMinutes:0, realName:"08PM"},{startHour: 21, isChecked:0, CheckedMinutes:0, realName:"09PM"},{startHour: 22, isChecked:0, CheckedMinutes:0, realName:"10PM"},{startHour: 23, isChecked:0, CheckedMinutes:0, realName:"11PM"}]

    },
    {
      dayName:'Sunday',
      dayId: 0,
      timeInterval:[{startHour: 0, isChecked:0, CheckedMinutes:0, realName:"12AM"},{startHour: 1, isChecked:0, CheckedMinutes:0, realName:"01AM"},{startHour: 2, isChecked:0, CheckedMinutes:0, realName:"02AM"},{startHour: 3, isChecked:0, CheckedMinutes:0, realName:"03AM"},{startHour: 4, isChecked:0, CheckedMinutes:0, realName:"04AM"},{startHour: 5, isChecked:0, CheckedMinutes:0, realName:"05AM"},{startHour: 6, isChecked:0, CheckedMinutes:0, realName:"06AM"},{startHour: 7, isChecked:0, CheckedMinutes:0, realName:"07AM"},{startHour: 8, isChecked:0, CheckedMinutes:0, realName:"08AM"},{startHour: 9, isChecked:0, CheckedMinutes:0, realName:"09AM"},{startHour: 10, isChecked:0, CheckedMinutes:0, realName:"10AM"},{startHour: 11, isChecked:0, CheckedMinutes:0, realName:"11AM"},{startHour: 12, isChecked:0, CheckedMinutes:0, realName:"12PM"},{startHour: 13, isChecked:0, CheckedMinutes:0, realName:"01PM"},{startHour: 14, isChecked:0, CheckedMinutes:0, realName:"02PM"},{startHour: 15, isChecked:0, CheckedMinutes:0, realName:"03PM"},{startHour: 16, isChecked:0, CheckedMinutes:0, realName:"04PM"},{startHour: 17, isChecked:0, CheckedMinutes:0, realName:"05PM"},{startHour: 18, isChecked:0, CheckedMinutes:0, realName:"06PM"},{startHour: 19, isChecked:0, CheckedMinutes:0, realName:"07PM"},{startHour: 20, isChecked:0, CheckedMinutes:0, realName:"08PM"},{startHour: 21, isChecked:0, CheckedMinutes:0, realName:"09PM"},{startHour: 22, isChecked:0, CheckedMinutes:0, realName:"10PM"},{startHour: 23, isChecked:0, CheckedMinutes:0, realName:"11PM"}]
    }
  ];
  const [allWeaks, setAllWeeks] = useState([]);

  useEffect(() => {
    fetchDetails();
    setzoneType(zLabel.isTrue);
  }, [setZoneData]);
  const getDays = () => { 
    var month = [];
    // const result = getDaysInCurrentMonth();
    for(let i=0; i <=11; i++){
      let origanalTime;
      let date = "0"+i+"a";
      if(i == 0){
        date = "12a";
        origanalTime = 24;
      } 
      if(i > 0){
        date = i+"a";
        origanalTime = i;
      } 
      month.push({"id":i, "val": date,"isChecked":false,"origanalTime":origanalTime})
    }
    for(let i=0; i <=11; i++){
      let origanalTime;
      let date = "0"+i+"p";
      if(i == 0){
        date = "12p";
        origanalTime = 12
      } 
      if(i > 0){
        date = i+"p";
        origanalTime = 12+i;
      } 
      month.push({"id":i, "val": date,"isChecked":false,"origanalTime":origanalTime})
    }
    setDays(month)
  }
  const getHoursAndWeek = () =>{
    setAllWeeks(week_names);
  }

  const fetchDetails = () => {
    getDays();
    getHoursAndWeek();
    apiHandler({
      url: endpoint.AVAILABLE_ZONES + "/" + `${activeParticipantId}` + "/zones",
      authToken,
    }).then((result) => {
      setAddZone("");
      setZoneName("");
      setCircleRadius(parseInt(20 / 3.2808));
      setRadius(20);
      zLabel.isTrue = true;
      setzoneType(zLabel.isTrue);
      setZoneData(result.data);
    });
  };

  const closeModal = () => {
    setAddZone("");
    setZoneName("");
    setzoneType("");
    setRadius("");
    setPlaces({});
    setMapData({ lat: 48.8584, lng: 2.2945 });
    setShapeType("Circle");
    inputEl.current.value = "";
    setModalVisible(false);
    setScheduleData([]);
    const elements = Array.from(document.getElementsByClassName("bg_blue2"));
     // let elements = document.querySelectorAll(['classname="dayclass_'+dayId+'']);
      
      elements.map(element=>{
        element.classList.remove("bg_blue2");
        element.classList.add("bg_white2");
    })
  };

  // Set google auto complete and map API
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyB2XkjP3mcKLJWvigvvhOBG0QF4msTR6EQ",
    libraries: ["places"],
  });
  const googleKey = "AIzaSyB2XkjP3mcKLJWvigvvhOBG0QF4msTR6EQ";
  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    console.log("map on onLoad : ",map)
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  // Save GPS  Zone data
  const saveGps = () => {
    console.log("isExclusion : ", zoneType ? false : true);
    // return;
    var error = 0;
    var errorMsg = "";
    if (zoneName == "") {
      error += 1;
      errorMsg = "Zone name is required";
    } else if (shapeType == "") {
      error += 1;
      errorMsg = "Shape is required";
    } else if (shapeType == "Circle" && radius < 0) {
      error += 1;
      errorMsg = "Invalid Radius";
    } else if (shapeType == "Circle" && coordinates.length == 0) {
      error += 1;
      errorMsg = "Coorinates not found";
    } else if (
      (shapeType == "Rectangle" || shapeType == "Polygon") &&
      rectangleCoordinate.length == 0
    ) {
      error += 1;
      errorMsg = "Coorinates not found";
    }

    if (error == 0) {
      var payload = [];
      if (shapeType == "Circle") {
        const coord = [coordinates];

        const sendCoord = coord.map(
          ({ lat: latitude, lng: longitude, ...rest }) => ({
            latitude,
            longitude,
            ...rest,
          })
        );

        payload.push({
          name: zoneName,
          coordinates: sendCoord,
          radius: parseInt(radius),
          // storing true if slectedZone type is 'isExclusionZone' and its corresponding bool value is false. thats why negation is important.
          isExclusionZone: !zoneType,
          typeOfShape: shapeType.toUpperCase(),
          testSchedule: {
            testPeriods: scheduleData.filter(x => x)
          }
        });
      }
      if (shapeType == "Rectangle" || shapeType == "Polygon") {
        const sendCoord = rectangleCoordinate.map(
          ({ lat: latitude, lng: longitude, ...rest }) => ({
            latitude,
            longitude,
            ...rest,
          })
        );

        payload.push({
          name: zoneName,
          coordinates: sendCoord,
          radius: 0,
           // storing true if slectedZone type is 'isExclusionZone' and its corresponding bool value is false, thats why negation is important to store the correct bool value.
          isExclusionZone: !zoneType,
          typeOfShape: shapeType.toUpperCase(),
          testSchedule: {
            testPeriods: scheduleData.filter(x => x)
          }
        });
      }
      
      
      //console.log("payload :", payload);
      // return
      
      apiHandler({
        url:
          endpoint.AVAILABLE_ZONES + "/" + `${activeParticipantId}` + "/zones",
        authToken,
        method: "POST",
        data: payload,
      }).then((result) => {
        // console.log(result)
        closeModal();
        notification.success({
          description: "Zone added successfully",
          placement: "topRight",
          duration: 5,
        });
      });
    } else {
      notification.error({
        description: errorMsg,
        placement: "topRight",
        duration: 5,
      });
    }
  };

  const updateGps = () => {
    // console.log(zoneName)
    // console.log(coordinates)
    // console.log(radius)
    // console.log(zoneType)
    var error = 0;
    var errorMsg = "";
    if (zoneName == "") {
      error += 1;
      errorMsg = "Zone name is required";
    } else if (shapeType == "") {
      error += 1;
      errorMsg = "Shape is required";
    } else if (shapeType == "Circle" && coordinates.length == 0) {
      error += 1;
      errorMsg = "Coorinates not found";
    } else if (
      (shapeType == "Rectangle" || shapeType == "Polygon") &&
      rectangleCoordinate.length == 0
    ) {
      error += 1;
      errorMsg = "Coorinates not found";
    }

    if (error == 0) {
      var payload = [];
      if (shapeType == "Circle") {
        payload.push({
          name: zoneName,
          coordinates: coordinates,
          radius: parseInt(radius),
          isExclusionZone: zoneType ? false : true,
          typeOfShape: shapeType,
        });
      }
      if (shapeType == "Rectangle" || shapeType == "Polygon") {
        payload.push({
          name: zoneName,
          coordinates: rectangleCoordinate,
          radius: 0,
          isExclusionZone: zoneType ? false : true,
          typeOfShape: shapeType,
        });
      }

      apiHandler({
        url:
          endpoint.AVAILABLE_ZONES + "/" + `${activeParticipantId}` + "/zones",
        authToken,
        method: "POST",
        data: payload,
      }).then((result) => {
        closeModal();
        notification.success({
          description: "Zone added successfully",
          placement: "topRight",
          duration: 5,
        });
      });
    } else {
      notification.error({
        description: errorMsg,
        placement: "topRight",
        duration: 5,
      });
    }
  };

  const options = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    clickable: true,
    draggable: true,
    editable: true,
    radius: 50,
    visible: true,
    zIndex: 1,
  };

  const onLoaded = (circle) => {
    // console.log('Circle onLoad circle: ', circle)
  };
  const dragCricle = () => {
    if (circleRef.state) {
      circleRef.state.circle.editable_changed((e)=>{
        console.log("e here",e);
      })
      console.log( circleRef.state.circle.editable_changed(),'Drag here m', circleRef.state.circle.radius.toFixed(2)* 3.2808)
      setRadius(+circleRef.state.circle.radius.toFixed(2)*3.2808);
      setCircleRadius(+circleRef.state.circle.radius.toFixed(2));
    }
  };
  const onUnmountLoad = (circle) => {
    console.log("Circle onUnmount circle: ", circle);
  };

  const changeShapeType = (e, zoneTP) => {
  
    setShapeType(e);
    if (e === "Rectangle") {
        var triangle1 = window.google.maps.geometry.spherical.computeOffset(
          places.geometry?.location,
          50,
          0
          );
          console.log("inside ",triangle1)
        var triangle2 = window.google.maps.geometry.spherical.computeOffset(
          places.geometry?.location,
          0,
          120
        );
        var triangle3 = window.google.maps.geometry.spherical.computeOffset(
          places.geometry?.location,
          88,
          -90
        );
        var triangle4 = window.google.maps.geometry.spherical.computeOffset(
          places.geometry?.location,
          100,
          -60
        );
        setRectangleCoordinate([]);
        var t1 = {
          lat: parseFloat(triangle1.lat()),
          lng: parseFloat(triangle1.lng()),
        };
        var t2 = {
          lat: parseFloat(triangle2.lat()),
          lng: parseFloat(triangle2.lng()),
        };
        var t3 = {
          lat: parseFloat(triangle3.lat()),
          lng: parseFloat(triangle3.lng()),
        };
        var t4 = {
          lat: parseFloat(triangle4.lat()),
          lng: parseFloat(triangle4.lng()),
        };
        console.log("t-1",t1)
        console.log("t-2",t2)
        console.log("t-3",t3)
        console.log("t-4",t4)
        console.log("rect path", [triangle1, triangle2, triangle3, triangle4]);
        console.log("rect coord", [t1, t2, t3, t4]);
    
        setRectanglePath([triangle1, triangle2, triangle3, triangle4]);
        setRectangleCoordinate([t1, t2, t3, t4]);

     

      if (zoneTP) {
        console.log("zonetype now is  if:",zoneTP);
        // rectangleRef.current.setOptions({ fillColor: "#32a858",strokeColor: "#32a858"});
        setDynamicOptions({
          strokeColor: "#32a858",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#32a858",
          fillOpacity: 0.35,
        });
      } else {
        console.log("zonetype now is  else:",zoneTP);

        // rectangleRef.current.setOptions({ fillColor: "#FF0000",strokeColor: "#FF0000"});
        setDynamicOptions({
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
        });
      }
    }
    if (e === "Polygon") {
      var triangle1 = window.google.maps.geometry.spherical.computeOffset(
        places.geometry?.location,
        50,
        0
      );
      var triangle2 = window.google.maps.geometry.spherical.computeOffset(
        places.geometry?.location,
        50,
        120
      );
      var triangle3 = window.google.maps.geometry.spherical.computeOffset(
        places.geometry?.location,
        50,
        -120
      );
      setPolygonPath([triangle1, triangle2, triangle3]);
      if (zoneTP) {
        // polygonRef.current.setOptions({ fillColor: "#32a858",strokeColor: "#32a858"});
        setDynamicOptions({
          strokeColor: "#32a858",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#32a858",
          fillOpacity: 0.35,
        });
      } else {
        //polygonRef.current.setOptions({ fillColor: "#FF0000",strokeColor: "#FF0000"});
        setDynamicOptions({
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
        });
      }
    }
    if (e == "Circle") {
      // if zone type is in enclusion
      if (zoneTP) {
        //since we are storing false for isExclusionZone so we need to negate it so that we can get true which means its a inclusionZone

        setDynamicOptions({
          strokeColor: "#32a858",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#32a858",
          fillOpacity: 0.35,
          clickable: true,
          draggable: true,
          editable: true,
          radius: 50,
          visible: true,
          zIndex: 1,
        });
      } else {
        // if zone type is in exclusion
        // circleRef.current.setOptions({ fillColor: "#FF0000",strokeColor: "#FF0000"});
        setDynamicOptions({
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
          clickable: true,
          draggable: true,
          editable: true,
          radius: 50,
          visible: true,
          zIndex: 1,
        });
      }
    }
    // const myPolygon = new window.google.maps.Polygon({
    //   path: triangleCoords,
    //   strokeColor: '#FF0000',
    //   strokeOpacity: 0.8,
    //   strokeWeight: 2,

    //   fillColor: '#FF0000',
    //   fillOpacity: 0.35
    // });
    // myPolygon.setMap(map);
  };

  const changeZone = (e) => {
    setScheduleData([])
    setAddZone(e);
    if (e == "NewZone") {
      setRadius(20);
      setZoneName("");
      zLabel.isTrue = true;
      inputEl.current.value = "";
      setzoneType(zLabel.isTrue);
      setMapData({ lat: 48.8584, lng: 2.2945 });
      setShapeType("Circle");
      setShow(false);
    } else {
      setShow(true);
      zoneData.forEach((row) => {
        if (row.id == e) {
          console.log("row here------", row);
          // let latAndLang = row['coordinates'].split(",");
          setMapData({
            lat: +row.coordinates[0].latitude,
            lng: +row.coordinates[0].longitude,
          });
          setScheduleData(row.testSchedule.testPeriods);
          let objArr = [];
          week_names.map(week=>{
            week.timeInterval.map(day=>{
              row.testSchedule.testPeriods.map(testEvent=>{
                if(testEvent.periodLength == 24 && testEvent.dayOfWeek == week.dayId){
                  day.isChecked =1
                  day.CheckedMinutes =1
                  let getDayElement = document.getElementById("day_id_"+week.dayId)
                  getDayElement.classList.add("bg_blue2")
                }else{
                  if(testEvent?.startHour == day?.startHour && testEvent?.dayOfWeek == week.dayId){
                    day.isChecked = 1;
                    //console.log("work here",week_names);
                  }
                  if(testEvent?.startHour == day?.startHour && testEvent?.dayOfWeek == week.dayId && testEvent?.startMinute ==30){
                    day.CheckedMinutes = 1;
                    //console.log("work here",week_names);
                  }

                }
                
                
              })
            })
            objArr.push(week);
          });
          setAllWeeks(objArr);
          //     fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${row?.latitude},${row?.longitude}&key=${googleKey}`).then(res => res.json())
          // .then(add => {

          //   if(add?.plus_code?.compound_code){
          //      //row["location"] = add.plus_code.compound_code;
          //   }
          // });
          let geocoder = new window.google.maps.Geocoder();
          var latlng = new window.google.maps.LatLng(
            row.coordinates[0].latitude,
            row.coordinates[0].longitude
          );
          geocoder
            .geocode({
              location: latlng,
            })
            .then(({ results }) => {
              console.log("result ltn ", results);
              setPlaces(results[0]);
              inputEl.current.value = results[0].formatted_address;
            })
            .catch((e) =>
              window.alert(
                "Geocode was not successful for the following reason: " + e
              )
            );

          setRadius(row["radius"]);
          setAddZone(row["name"]);
          setZoneName(row["name"]);
          //since zoneType selection dropdown is giving true if selectedTab is zoneInclusion and false for zoneExclusion , so we need to negate the zoneType value so that we can store true for 'isExclusionZone' it provides better zone readibility. so suppose user has choosed 'isExclusionZone' which has value false so this negation will store true for it.
          setzoneType(!row["isExclusionZone"]);
          setCoordinates({
            lat: parseInt(row.coordinates[0].latitude),
            lng: parseInt(row.coordinates[0].longitude),
          });
          //setMapData({ lat: parseInt(row.coordinates[0].latitude), lng: parseInt(row.coordinates[0].longitude) })
          setShapeType("Circle");
          // this will render the correct bg color for the circle.
          changeShapeType("Circle", !row["isExclusionZone"]);
        }
      });
    }
  };

  const changeRadius = (e) => {
    console.log("e here", e.target.value);
    if (e.target.value && e.target.value>=1) {
      setRadius(parseInt(e.target.value).toFixed(2));
      setCircleRadius(parseInt(e.target.value) / 3.2808);
    }
    else if(e.target.value<1){
      setRadius(0);
      setCircleRadius(0);
    }
    else {
      setRadius(20);
      setCircleRadius(20);
    }
    // setRadius(e.target.value);
    // options['radius'] = parseInt(e.target.value);

    // console.log('radius here',window.google.maps.Circle)
  };

  const onEditPoly = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      console.log(nextPath);
      setRectangleCoordinate([]);
      setRectangleCoordinate(nextPath);
      //  console.log(rectangleCoordinate);
    }
  });
  const selectDayAll = (e,dayId) =>{
    const elements = Array.from(document.getElementsByClassName("dayclass_"+dayId));
    if(e.currentTarget.classList.contains("bg_blue2") == false){
      e.currentTarget.classList.add("bg_blue2")
     // let elements = document.querySelectorAll(['classname="dayclass_'+dayId+'']);
      
      elements.map(element=>{
        element.classList.add("bg_blue2");
    })
    let obj = {
      "startHour": 0,
      "startMinute": 0,
      "periodLength": 24,
      "testCount": 1,
      "isRandom": false,
      "dayOfWeek": dayId
  };
  scheduleData.push(obj);

    }else{
      e.currentTarget.classList.remove("bg_blue2");
      e.currentTarget.classList.add("bg_white2")
      elements.map(element=>{
        element.classList.remove("bg_blue2");
        element.classList.add("bg_white2");
    })
    scheduleData.forEach((el,i)=>{
      if(el?.startHour == 0 && el?.dayOfWeek == dayId && el?.startMinute == 0){
        delete scheduleData[i];
      }
    })
    }
  }
  const checkDateOrange = (index,e,dayid,isMinutes,isTrueMinutes) => {
    
    if(e.currentTarget.classList.contains("bg_blue2") == false){
      let obj = null;
      // scheduleData.forEach(el=>{
      //   console.log("el here",el, "dayid here",dayid,"index",index);
      //   if(el?.startHour == index && el?.dayOfWeek == dayid ){
      //     if(isTrueMinutes){
      //      el.startMinute = 30;
      //     }else{
      //       el.startMinute = 0;
      //     }
      //   }else{
      //     if(isTrueMinutes){
      //       obj = {
      //         "startHour": index,
      //         "startMinute": 30,
      //         "periodLength": 1,
      //         "testCount": 1,
      //         "isRandom": false,
      //         "dayOfWeek": dayid
      //     };
      //     }else{
      //       obj = {
      //         "startHour": index,
      //         "startMinute": 0,
      //         "periodLength": 1,
      //         "testCount": 1,
      //         "isRandom": false,
      //         "dayOfWeek": dayid
      //     };
      //   }
      // }
      // })
        if(isTrueMinutes){
          obj = {
            "startHour": index,
            "startMinute": 30,
            "periodLength": 29,
            "testCount": 1,
            "isRandom": false,
            "dayOfWeek": dayid
        };
        }else{
          obj = {
            "startHour": index,
            "startMinute": 0,
            "periodLength": 29,
            "testCount": 1,
            "isRandom": false,
            "dayOfWeek": dayid
        };
      }
      
     scheduleData.push(obj);
     console.log("scheduleData",scheduleData);
       e.currentTarget.classList.add("bg_blue2")
     }
     else {
       e.currentTarget.classList.remove("bg_blue2");
       e.currentTarget.classList.add("bg_white2")
       scheduleData.forEach((el,i)=>{
          let i_minutes = 0;
          if(isTrueMinutes){
              i_minutes = 30
          }
         if(el?.startHour == index && el.startMinute == i_minutes && el?.dayOfWeek == dayid){
           delete scheduleData[i];
           console.log("scheduledata; ",scheduleData);
         }
       })  
     }
   
  }
  const checkDateBlueTw = (index,e,dayid,isMinutes) => {
    
    if(e.currentTarget.classList.contains("bg_blue2") == false){
     let obj = {
        "startHour": index,
        "startMinute": 0,
        "periodLength": 1,
        "testCount": 1,
        "isRandom": false,
        "dayOfWeek": dayid
    };
    scheduleData.push(obj);
      e.currentTarget.classList.add("bg_blue2")
    }
    else {
      e.currentTarget.classList.remove("bg_blue2");
      scheduleData.forEach((el,i)=>{
        if(el?.startHour == index && el?.dayOfWeek == dayid){
          delete scheduleData[i];
          console.log("scheduledata; ",scheduleData);
        }
      })  
    }
    
  }
  //const open
  const onLoadPolygon = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path?.addListener("set_at", onEditPoly),
        path?.addListener("insert_at", onEditPoly),
        path?.addListener("remove_at", onEditPoly)
      );
    },
    [onEditPoly]
  );
  
  const onChangeZoneType = (value, shapeType) => {
    console.log("zone value", value);

    setzoneType(value);
    changeShapeType(shapeType, value);
  };
  
  const handleZoomChange = () => {
    if (map) {
      console.log("map on zoom",map.getZoom());
      //setRadius(RADIUS_ON_ZOOM['ZOOM_'+map.getZoom()]);
    }
  };

  return (
    <>
      <StyledButton
        externalCss={css`
          background: #fff;
          border: 1px solid #0557a2;
          color: #0557a2;
          padding: 10px;
          margin: 15px 5px;
        `}
        onClick={() => {
          fetchDetails();
          setModalVisible(true);
        }}
      >
        Manage Zones
      </StyledButton>

      <StyledModal
        visible={modalVisible}
        closable={false}
        footer={null}
        onCancel={closeModal}
        title={[
          <Row>
            <Col md={9} style={{ marginTop: "5px", fontWeight: "bold" }}>
              Manage GPS Zones
            </Col>
            <Col md={15}>
              {addZone == "NewZone" ? (
                <Button
                  type="primary"
                  disabled={addZone == "" || !addZone}
                  onClick={() => saveGps()}
                  style={{ float: "right", marginLeft: "1rem" }}
                >
                  Save
                </Button>
              ) : (
                <Button
                  type="primary"
                  disabled={addZone == "" || !addZone}
                  onClick={() => updateGps()}
                  style={{ float: "right", marginLeft: "1rem" }}
                >
                  Update
                </Button>
              )}
              <Button style={{ float: "right" }} onClick={closeModal}>
                Cancel
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Row>
          <Col md={3} className="mt-5">
            Zone:
          </Col>

          <Col md={9}>
            {addZone == "NewZone" ? (
              <Input
                placeholder="Enter Zone Name"
                onChange={(e) => setZoneName(e.target.value)}
                style={{ width: "85%", borderRadius: "0px" }}
              />
            ) : (
              <></>
            )}

            {addZone != "NewZone" || addZone == "" ? (
              <Select
                placeholder="Select zone"
                style={{ width: "85%" }}
                value={addZone}
                onChange={(e) => changeZone(e)}
              >
                <Option value="">Select Zone</Option>
                <Option value="NewZone" key="0">
                  Create New Zone
                </Option>
                {zoneData.map((zone) => (
                  <Option value={zone.id} key={zone.id}>
                    {zone.name}
                  </Option>
                ))}
              </Select>
            ) : (
              <></>
            )}
          </Col>

          <Col md={9}>
            <Autocomplete
              apiKey={"AIzaSyB2XkjP3mcKLJWvigvvhOBG0QF4msTR6EQ"}
              style={{
                width: "90%",
                border: "1px solid #d9d9d9",
                borderRadius: "2px",
                padding: "4px",
              }}
              ref={inputEl}
              onPlaceSelected={(place) => {
                console.log("places : ",place)
                setMapData({
                  lat: place.geometry?.location.lat(),
                  lng: place.geometry?.location.lng(),
                });
                setPlaces(place);
                setShow(true);
                setCoordinates({
                  lat: place.geometry?.location.lat(),
                  lng: place.geometry?.location.lng(),
                });
              }}
              options={{
                types: ["geocode", "establishment"],
                // componentRestrictions: { country: "us" },
              }}
            />
          </Col>
        </Row>
          <Row>
          <table className='table'>
                <thead>
                  <tr>
                    <th></th>
                    { week_names[0].timeInterval.map((day,i) => <th>{day.realName}</th>) }
                    <th>&nbsp; &nbsp;All Day</th>
                  </tr>
                </thead>
                <tbody>
                  { allWeaks.map((day,i) => <tr><td key={day.dayId}>{day.dayName}</td>
                 
                      { day.timeInterval.map((mnd,i) => 
                      <td>
                        <div style={{ display: "flex" }}>
                           {mnd.isChecked == 0 ? (
                            <div className={`square dayclass_${day.dayId}`} onClick={(e) => { checkDateOrange(i,e,day.dayId,mnd.CheckedMinutes,0)}} style={{ marginRight: "2px" }}></div>
                             ):
                             <div className={`square bg_blue  dayclass_${day.dayId}`} onClick={(e) => { checkDateOrange(i,e,day.dayId,mnd.CheckedMinutes,0)}} style={{ marginRight: "2px" }}></div>
                           }
                            {mnd.CheckedMinutes == 0 ? (
                             <div className={`square  dayclass_${day.dayId}`} onClick={(e) => { checkDateOrange(i,e,day.dayId,mnd.CheckedMinutes,1)}}></div>
                             ):
                             <div className={`square bg_blue  dayclass_${day.dayId}`} onClick={(e) => { checkDateOrange(i,e,day.dayId,mnd.CheckedMinutes,0)}}></div>
                           }
                         
                        </div>
                      </td>
                      
                    ) }
                    <td>
                       <div style={{ display: "flex" }}>
                             &nbsp; &nbsp; &nbsp; &nbsp;<div className="square" id={`day_id_${day.dayId}`} style={{ marginRight: "2px" }} onClick={(e) => { selectDayAll(e,day.dayId)}}></div>
                            
                       </div>
                     </td>
                  </tr>) }
                </tbody>
              </table>
          </Row>
        <Row style={{ marginTop: "1rem" }}>
          <GoogleMap
            onZoomChanged={handleZoomChange}
            mapContainerStyle={containerStyle}
            center={mapData}
            zoom={10}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            <Row className="mt-15">
              {/* <Col md={7} >
              </Col> */}
              <Col md={6} style={{ marginLeft: "29.16666667%" }}>
                <Select
                  placeholder="Select"
                  onChange={(e) => {
                    onChangeZoneType(e, shapeType);
                  }}
                  style={{ width: "80%" }}
                  value={zoneType}
                >
                  <Option value="">Select Zone Type</Option>
                  <Option value={zLabel.isTrue} selected>
                    Inclusion Zone
                  </Option>
                  <Option value={zLabel.isFalse}>Exclusion Zone</Option>
                </Select>
              </Col>
              <Col md={4}>
                {shapeType === "Circle" ? (
                  <Input
                    type="text"
                    placeholder="Radius (ft)"
                    onChange={(e) => changeRadius(e)}
                    value={radius}
                  />
                ) : (
                  <></>
                )}
              </Col>
              {isShow == false ? (
                <Col md={3}></Col>
              ) : (
                <>
                  {shapeType == "" ? (
                    <Col md={3}></Col>
                  ) : (
                    <>
                      <Col
                        md={1}
                        style={{ marginLeft: "10px", marginRight: "15px" }}
                      >
                        <Button
                          type={shapeType === "Circle" ? "primary" : ""}
                          onClick={() => changeShapeType("Circle", zoneType)}
                        >
                          &#9711;
                        </Button>
                      </Col>
                      <Col
                        md={1}
                        style={{ marginRight: "15px" }}
                        onClick={() => changeShapeType("Rectangle", zoneType)}
                      >
                        <Button
                          type={shapeType === "Rectangle" ? "primary" : ""}
                        >
                          &#9645;
                        </Button>
                      </Col>
                      <Col md={1}>
                        <Button
                          type={shapeType === "Polygon" ? "primary" : ""}
                          onClick={() => changeShapeType("Polygon", zoneType)}
                        >
                          &#9651;
                        </Button>
                      </Col>
                    </>
                  )}
                </>
              )}
              ;
            </Row>

            <Marker position={mapData} />

            {shapeType == "Circle" && shapeType != "" ? (
              <Circle
                onLoad={onLoaded}
                ref={(ref) => (circleRef = ref)}
                onRadiusChanged={dragCricle}
                onUnmount={onUnmountLoad}
                radius={circleRadius}
                center={mapData}
                options={dynamicOptions}
              />
            ) : (
              <></>
            )}

            {shapeType == "Polygon" && shapeType != "" ? (
              <Polygon
                onMouseUp={onEditPoly}
                ref={polygonRef}
                draggable={true}
                editable={true}
                onLoad={onLoadPolygon}
                options={dynamicOptions}
                onClick={function (event) {
                  console.log("event here:", event);
                }}
                paths={polygonPath}
              />
            ) : (
              <></>
            )}

            {shapeType == "Rectangle" && shapeType != "" ? (
              <Polygon
                draggable={true}
                editable={true}
                ref={rectangleRef}
                onLoad={onLoadPolygon}
                options={dynamicOptions}
                onClick={function (event) {
                  console.log("event here:", event);
                }}
                paths={rectanglePath}
              />
            ) : (
              <></>
            )}
          </GoogleMap>
        </Row>
      </StyledModal>
    </>
  );
};

export default ManageZones;
